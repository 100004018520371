@import "../../../../styles/index.scss";

.uniqueSolution {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;  
  max-width: 1150px;
  width: 100%;
  margin: 100px 0;
  position: relative;
  @media (max-width: 1000px) {
    padding: 0 20px;
    box-sizing: border-box;
  }
  @media (max-width: 450px) {
    padding: 0 10px;      
  } 
  &__title {
    @include mr-500(50px, normal, unset);
    color: $main-text;
    margin-bottom: 40px;
    @media (max-width: 1200px) {
      @include mr-500(35px, normal, unset);
    }
    @media (max-width: 890px) {
      text-align: center;
    }
  }
  &__subtitle {
    @include montserrat-500(25px, 154%);
    color: $main-text;
    @media (max-width: 1200px) {
      @include montserrat-500(20px, 154%);
    }
    @media (max-width: 890px) {
      text-align: center;
    }    
  }
  &__wrapperBlocks {    
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    @media (max-width: 890px) {
      display: none;
    }
    .block {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      max-width: 445px;
      width: 100%;
      margin-top: 40px;
    }
    .wrapperTitle {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;      
      align-items: flex-start;
      margin-bottom: 30px;
    }
    .title {
      @include montserrat-700(25px, 20px);
      color: $akcent-yellow;
      margin-left: 18px;
      @media (max-width: 1200px) {
        @include montserrat-700(18px, 130%);
      }
    }
    .text {
      @include montserrat-500(20px, 125%);
      color: $main-text;
      @media (max-width: 1200px) {
        @include montserrat-500(16px, 145%);
      }
    }
  }

  &__wrapperBlocksMobile {
    display: none;   
    max-width: 600px;
    width: 100%;
    @media (max-width: 890px) {
      display: block;
    }
    .customBullet {
      width: 9px;
      height: 9px;
      background-color: #FFF4DE;
      border: 1px solid $akcent-yellow;
      transform: rotate(45deg);
      border-radius: 0;
      opacity: 1;    
    }
    .customBulletActive {    
      background-color: $akcent-yellow;       
    }
    .mySwiperSlideMobile {
      margin-bottom: 60px;
    }
    .block {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;      
      width: 100%;
      margin-top: 40px;
    }
    .wrapperTitle {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;      
    }
    .title {
      @include montserrat-700(18px, 130%);
      color: $akcent-yellow;
      margin-left: 18px;      
    }
    .text {
      @include montserrat-400(18px, normal);
      color: $main-text;     
    }
  }
  .img1, .img2, .img3 {
    position: absolute;
    z-index: -4;
    max-width: 220px;
    width: 100%;
    @media (max-width: 1100px) {
      max-width: 130px;
    }
    @media (max-width: 1020px) {
      display: none;            
    }
  }
  .img1 {
    top: 15%;
  }
  .img2 {
    top: 50%;
  }
  .img3 {
    top: 85%;
  }
}
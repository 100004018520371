@import "../../../../styles/index.scss";

.feedbackForm {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;  
  max-width: 1150px;
  width: 100%;
  margin-bottom: 100px;
  @media (max-width: 1070px) {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  &__img {
    max-width: 550px;
    width: 100%;
    @media (max-width: 1070px) {
      margin-top: 30px;
    }
  }
  &__wrapperForm {
    position: relative;
    padding: 30px 50px;
    border: 1px solid rgba(255, 184, 0, 0.65);
    max-width: 450px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    @media (max-width: 560px) {
      box-sizing: border-box;
      padding: 25px 17px; 
      max-width: 450px;     
    }
    .iconMobile {
      display: none;
      position: absolute;
      right: 8%;
      @media (max-width: 1070px) {
        display: block;
      }
      @media (max-width: 560px) {
       display: none;      
      }
    }
    .title {
      @include mr-500(35px, normal);
      color: $akcent-yellow;      
      margin: 0 auto 20px auto;
      @media (max-width: 1200px) {
        @include mr-500(25px, 30px, 0.5px);
      }
    }
    .subtitle {
      @include montserrat-400(16px, normal);
      color: $main-text;
      margin-bottom: 30px;
      @media (max-width: 1070px) {
        text-align: center;
        margin: 0 auto 30px auto;
      }
      @media (max-width: 560px) {
        @include montserrat-400(14px, normal);      
      }      
    }
    .form {
      max-width: 450px;
      width: 100%;
    }
    .text {
      @include montserrat-400(16px, normal);
      color: $main-text;
      margin-bottom: 7px;
      @media (max-width: 560px) {
        @include montserrat-400(14px, normal);      
      } 
    }
    .name, .numberPhone {
      // margin-bottom: 20px;
      border: 1px solid rgba(255, 184, 0, 0.65);
      @include montserrat-400(16px, normal);
      color: #7D7D7D;
      max-width: 425px;
      width: 100%;
      padding: 18px 0 18px 25px;
      @media (max-width: 560px) {
        @include montserrat-400(14px, normal);
        box-sizing: border-box;        
        max-width: 450px;       
      }
    }
    .button {
      max-width: 450px;
      width: 100%; 
      position: relative;
      display: flex;  
      justify-content: center;
      align-items: center;
      padding: 18px 31px;      
      cursor: pointer;
      border: 1.25px solid $akcent-yellow;
      background-color: $akcent-yellow;
      margin-top: 15px;
      @media (max-width: 560px) {
        padding: 10px 25px;       
      }
      .wrapperTextBtn {    
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      } 
      .buttonText{
        @include montserrat-500(18px, 98%);
        color: $main-text;
      }     
    }
    .button::after {
      position: absolute;
      top: 10%;
      content: "";
      display: block;
      max-width: 410px;
      width: 100%;
      min-height: 75px;
      height: 100%;
      border: 1.25px solid $main-text;  
      z-index: -3; 
      @media (max-width: 560px) {
        min-height: 60px;
        max-width: 380px;       
      }   
    } 
    .button:hover {
      background-color: $akcent-milk;
    }
    .wrapperError {
      height: 30px;
      display: flex;
      align-items: center;
      .error {
        @include montserrat-400(14px, normal);
        color: red;
      }
    }
    .wrapperCheckbox {
      display: flex;
      flex-direction: row;  
      justify-content: flex-start;
      align-items: center;
      margin-top: 20px;
      &__text {
        @include montserrat-400(16px, normal);
        color: $main-text;
        margin-left: 18px;
      }
    }
  }
}
@mixin montserrat-400($fontSize, $lineHeight: unset) {
  font-family: "Montserrat Regular";
  font-style: normal;
  font-weight: 400;
  font-size: $fontSize;
  line-height: $lineHeight;
}

@mixin montserrat-500($fontSize, $lineHeight: unset) {
  font-family: "Montserrat Medium";
  font-style: normal;
  font-weight: 500;
  font-size: $fontSize;
  line-height: $lineHeight;
}

@mixin montserrat-600($fontSize, $lineHeight: unset) {
  font-family: "Montserrat SemiBold";
  font-style: normal;
  font-weight: 600;
  font-size: $fontSize;
  line-height: $lineHeight;
}

@mixin montserrat-700($fontSize, $lineHeight: unset) {
  font-family: "Montserrat Bold";
  font-style: normal;
  font-weight: 700;
  font-size: $fontSize;
  line-height: $lineHeight;
}

@mixin mr-200($fontSize, $lineHeight: unset, $letterSpacing ){
  font-family: "mr_ApexMk3 Extra-light";
  font-style: normal;
  font-weight: 200;
  font-size: $fontSize;
  line-height: $lineHeight;
  letter-spacing: $letterSpacing;
}

@mixin mr-500($fontSize, $lineHeight: unset, $letterSpacing: unset ){
  font-family: "mr_ApexMk3 Medium";
  font-style: normal;
  font-weight: 500;
  font-size: $fontSize;
  line-height: $lineHeight;
  letter-spacing: $letterSpacing;
}
@import "../../../styles/index.scss";

.header {
  max-width: 1150px;  
  width: 100%;
  margin-top: 55px;  
  position: relative;
   
  .wrapperHeader {
    max-width: 1150px;  
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 1000px) {
      padding: 0 20px;
      box-sizing: border-box;
    }
    @media (max-width: 450px) {
      padding: 0 10px;      
    }
  } 
   
  &__list {
    max-width: 950px;    
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;    
    padding-bottom: 20px;
    border-bottom: 1.25px solid $akcent-milk; 
    @media (max-width: 720px) {
      border: none;
    }   
  }
  .link, .item {
    @include montserrat-500(16px, normal);
    color: $akcent-milk;
    text-decoration: none;    
  }
  .item {
    @media (max-width: 450px) {
      @include montserrat-500(14px, normal);
    }
    @media (max-width: 406px) {
      @include montserrat-500(10px, normal);
    }
    @media (max-width: 340px) {
      @include montserrat-500(8px, normal);
    }
    &__phone {
      cursor: pointer;
      font-weight: bold;
      @include montserrat-700(16px, normal);
      @media (max-width: 450px) {
        @include montserrat-700(14px, normal);
      }
      @media (max-width: 406px) {
        @include montserrat-700(10px, normal);
      }
      @media (max-width: 340px) {
        @include montserrat-500(8px, normal);
      }
    }
    &__email {
      cursor: pointer;
      @include montserrat-500(16px, normal);
      @media (max-width: 450px) {
        @include montserrat-500(14px, normal);
      }
      @media (max-width: 406px) {
        @include montserrat-500(10px, normal);
      }
      @media (max-width: 340px) {
        @include montserrat-500(8px, normal);
      }
    }
  }
  .link {
    @media (max-width: 720px) {
      display: none;
    }
  }
  .link:hover {
    text-decoration: underline;
  }
  .item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .itemBold {
    font-weight: bold;
  }
  .mobileBurgerBtn {
    display: none;
    @media (max-width: 720px) {
      display: block;
    }
    @media (max-width: 432px) {
      svg {
        width: 26px;
        height: 20px;
      }
    }
  }
  .visible {
    display: block;
  }
  .invisible {
    display: none;
  }
}

.burgerMenu {
  max-width: 730px;  
  width: 100%;
  min-height: 495px;
  background-color: $akcent-yellow;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 10;
  position: absolute;
  top: -50%;  
  &__list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &__item {
    @include montserrat-500(18px, 110%);
    color: $main-text;
    margin-bottom: 44px;
    text-decoration: none;
  }
  &__item:last-child {
    margin-bottom: 0;
  }
  &__btnClose {
    cursor: pointer;
    position: absolute;
    top: 10%;
    right: 10%;
  }

}
@import "../../../../styles/index.scss";

.services {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  max-width: 1150px;
  width: 100%;
  margin-top: 50px;  
  margin-bottom: 135px;
  @media (max-width: 1000px) {
    padding: 0 20px;
    box-sizing: border-box;
  }
  @media (max-width: 450px) {
    padding: 0 10px;      
  } 
  &__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;    
  }
  .icon {
    max-width: 166px;
    width: 100%;
  }
  .text {
    @include montserrat-500(16px, normal);
    color: $main-text;
    margin-top: 28px;
    max-width: 166px;
    width: 100%;
    text-align: center;
    max-width: 166px;
    width: 100%;
  }
}
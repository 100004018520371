@font-face {
  font-family: "Montserrat Regular";
  src: url("../../assets/fonts/Montserrat/Montserrat-Regular.ttf");
}

@font-face {
  font-family: "Montserrat Medium";
  src: url("../../assets/fonts/Montserrat/Montserrat-Medium.ttf");
}

@font-face {
  font-family: "Montserrat Bold";
  src: url("../../assets/fonts/Montserrat/Montserrat-Bold.ttf");
}

@font-face {
  font-family: "mr_ApexMk3 Extra-light";
  src: url("../../assets/fonts/mr_ApexMk3/mr_apexmk3_extralight.ttf");
}

@font-face {
  font-family: "mr_ApexMk3 Medium";
  src: url("../../assets/fonts/mr_ApexMk3/mr_apexmk3_medium.ttf");
}
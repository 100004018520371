@import "../../../../styles/index.scss";

.ourWorks {  
  background-color: #FCF9F3;
  max-width: 3500px;
  width: 100%;
  padding: 100px 0;  
  .wrapperOurWorks {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;  
    // @media (max-width: 1000px) {
    //   padding: 0 20px;
    //   box-sizing: border-box;
    // }
    // @media (max-width: 450px) {
    //   padding: 0 10px;      
    // }   
  }
  &__title {
    text-transform: uppercase;
    @include mr-500(50px, normal, unset);
    color: $main-text;
    margin-bottom: 60px;
    @media (max-width: 1200px) {
      @include mr-500(35px, normal, unset);    
    }
    @media (max-width: 790px) {
      text-align: center;    
    }
    @media (max-width: 470px) {
      @include mr-500(25px, normal, unset);          
    }
  }
  &__list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 42px;
    max-width: 1080px;
    width: 100%;
    cursor: pointer;
    @media (max-width: 790px) {
      flex-wrap: wrap;
      justify-content: center;          
    }
    @media (max-width: 790px) {
      margin-bottom: 20px;          
    }    
    .item {
      @include montserrat-500(25px, 154%);
      color: $main-text;
      cursor: pointer;
      @media (max-width: 1200px) {
        @include montserrat-500(18px, 130%);    
      }
      @media (max-width: 790px) {
        margin-bottom: 20px;
        margin-right: 20px;          
      }
      @media (max-width: 470px) {
        @include montserrat-500(14px, normal);    
      }
    } 
    .item:hover {
      color: $akcent-yellow;
    }   
    .itemSelected {
      @include montserrat-700(25px, 154%);
      color: $akcent-yellow;
      border-bottom: 3px solid $akcent-yellow;
      cursor: pointer;
      @media (max-width: 1200px) {
        @include montserrat-700(18px, 130%);    
      }
      @media (max-width: 790px) {
        margin-bottom: 20px;
        margin-right: 20px;          
      }
      @media (max-width: 470px) {
        @include montserrat-700(14px, normal);    
      }
    }
  }
  
  &__wrapperCards {
    display: flex;
    flex-direction: row;
    justify-content: space-between;    
    align-items: stretch;    
    max-width: 1150px;
    width: 100%;
    margin-bottom: 33px;
    @media (max-width: 1070px) {
      display: none;
    }  
  }
  &__wrapperBlocksMobile {
    display: none;   
    max-width: 620px;
    width: 100%;
    @media (max-width: 1070px) {
      display: block;
    }
    .customBullet {
      width: 9px;
      height: 9px;
      background-color: #FFF4DE;
      border: 1px solid $akcent-yellow;
      transform: rotate(45deg);
      border-radius: 0;
      opacity: 1;    
    }
    .customBulletActive {    
      background-color: $akcent-yellow;       
    }
    .mySwiperSlideMobile {
      margin-bottom: 60px;
    }    
  }
  &__button {
    width: 100%;
    max-width: 312px;
    z-index: 4;
    display: flex;
    justify-content: center;
    text-decoration: none;    
    @media (max-width: 1070px) {
      margin-top: 20px;
    }
  }
}
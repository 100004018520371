@import "../../../../styles/index.scss";

.bestSolution {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  width: 100%;
  padding-bottom: 100px;   
  
  .wrapperBestSolution {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    max-width: 1150px;
    width: 100%;
    margin-top: 100px;
    position: relative; 
    @media (max-width: 1000px) {
      padding: 0 20px;
      box-sizing: border-box;
    }
    @media (max-width: 450px) {
      padding: 0 10px;      
    }     
  }
  &__title {
    @include mr-500(50px, normal);
    color: $main-text;
    margin-bottom: 95px;
    text-align: center; 
    @media (max-width: 1200px) {
      @include mr-500(35px, normal);
    }   
  }  
  &__mainImg {
    max-width: 1150px;
    width: 100%;
  }
  &__wrapperBlocks {    
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 60px;
    @media (max-width: 800px) {
      justify-content: center;      
    }
    .block {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      max-width: 400px;
      width: 100%;
      margin-top: 65px;
    }
    .wrapperText {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      margin-left: 15px;
      margin-bottom: 10px;
    }
    .title {
      @include montserrat-700(20px, 20px);
      color: $main-text;
      margin-bottom: 10px;
      @media (max-width: 1200px) {
        @include montserrat-700(18px, 20px);
      }
    }
    .text {
      @include montserrat-500(20px, 125%);
      color: $main-text;
      @media (max-width: 1200px) {
        @include montserrat-500(18px, 125%);
      }
      @media (max-width: 640px) {
        @include montserrat-400(18px, normal);
      }
    }
  }
}
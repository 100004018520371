@import "../../../../styles/index.scss";

.designExamples {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;  
  max-width: 1150px;
  width: 100%;
  margin-bottom: 100px; 
  @media (max-width: 1000px) {
    padding: 0 20px;
    box-sizing: border-box;
  }
  @media (max-width: 450px) {
    padding: 0 10px;      
  }  
  &__title {
    text-transform: uppercase;
    @include mr-500(45px, normal);
    color: $main-text;
    margin-bottom: 40px;
    @media (max-width: 1440px) {
      @include mr-500(35px, normal);
    }
    @media (max-width: 960px) {
      text-align: center;
    }    
  }
  .wrapperExamples {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1150px;
    width: 100%;        
  }
  .example {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 40px;
    @media (max-width: 960px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    &__img {
      max-width: 400px;
      width: 100%;
      @media (max-width: 1060px) {
        max-width: 300px;
      }
      @media (max-width: 960px) {
        margin-bottom: 30px;
        max-width: 400px;
      }
    }
    &__wrapperText {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      max-width: 650px;
      width: 100%;
      @media (max-width: 960px) {
        max-width: 400px;
      }
      .title {
        @include mr-500(30px, normal);
        color: $akcent-yellow;
        margin-bottom: 20px;
        @media (max-width: 1440px) {
          @include mr-500(24px, normal);
        }
      }
      .text {
        @include montserrat-500(18px, normal);
        color: $main-text;
        @media (max-width: 1200px) {
          @include montserrat-500(16px, normal);
        }
      }
    }
    
  }
  .example:last-child {
    margin-bottom: 0;
  } 
  &__iconSquares {
    position: absolute;
    top: 40%;
    right: 10%;
    @media (max-width: 960px) {
      display: none;
    }
  } 
}
@import "../../../styles/index.scss";


// mainButton
.mainButtonLight, .mainButtonDark{
  position: relative;
  display: flex;  
  justify-content: center;
  align-items: center;
  padding: 18px 31px;
  max-width: 312px;
  width: 100%;
  cursor: pointer;
  border: 1.25px solid $akcent-milk;
  // @media (max-width: 700px) {
  //   max-width: 250px;   
  // }
  
  
  .wrapperText {    
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;    
  }
  .mainButtonTextLight, .mainButtonTextDark {
    @include montserrat-500(16px, 98%);    
  }
  .mainButtonTextDark {
    color: $akcent-milk;
  }  
  .mainButtonTextLight {
    color: $main-text;
  }  
  .mainButtonSvgDark {
    rect {
      stroke: $akcent-milk;      
    }
    path {
      fill: $akcent-milk;
    }
  }
}
.mainButtonLight {
  background-color: $akcent-yellow;
}
.mainButtonDark {
  background-color: $main-text;
}
.mainButtonLight::after, .mainButtonDark::after {
  position: absolute;
  top: 10%;
  content: "";
  display: block;
  max-width: 293px;
  width: 100%;
  min-height: 75px;
  height: 100%;
  border: 1.25px solid $akcent-milk;
  z-index: -2;  
}
.mainButtonLight:hover, .mainButtonDark:hover {
  background-color: $akcent-milk;
  .mainButtonTextDark {
    color: $main-text;
  }
  .mainButtonSvgDark {
    rect {
      stroke: $main-text;      
    }
    path {
      fill: $main-text;
    }
  }
}


//button

.buttonUnderlineLight, .buttonUnderlineDark {
  position: relative;
  display: flex;  
  justify-content: center;
  align-items: center;
  padding: 18px 31px;
  max-width: 312px;
  width: 100%;
  cursor: pointer;
  border: 1.25px solid $akcent-yellow;
  background-color: $akcent-yellow; 
  @media (max-width: 700px) {
    max-width: 250px;   
  } 
  
  .wrapperText {    
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .buttonTextLight{
    @include montserrat-500(16px, 98%);
    color: $main-text;
  }    
  
}
.buttonUnderlineDark {
  border-color: $main-text;
}
.buttonUnderlineLight::after, .buttonUnderlineDark::after {
  position: absolute;
  top: 10%;
  content: "";
  display: block;
  max-width: 293px;
  width: 100%;
  min-height: 75px;
  height: 100%;
  border: 1.25px solid $akcent-yellow;
  z-index: -2;
  @media (max-width: 700px) {
    max-width: 234px;   
  }
}

.buttonUnderlineDark::after {  
  border: 1.25px solid $main-text;  
}

.buttonUnderlineLight:hover, .buttonUnderlineDark:hover {
  background-color: $akcent-milk;
}


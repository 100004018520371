@import "../../../../styles/index.scss";

.reviews {
  background-color: #FCF9F3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 3500px;
  width: 100%;
  padding: 100px 0;
  .wrapperReviews {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;    
    max-width: 1150px;
    width: 100%;
  }
  &__title {
    text-transform: uppercase;
    @include mr-500(50px, normal);
    color: $main-text;
    margin-bottom: 60px;
    @media (max-width: 1200px) {
      @include mr-500(35px, normal);    
    }
    @media (max-width: 680px) {
      @include mr-500(25px, normal);
      text-align: center;    
    }
  }
  .mySwiper {
    max-width: 1150px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between; 
    overflow-y: visible;  
    .swiper-pagination-bullet {
      width: 10px;
      height: 10px;
      background-color: $akcent-yellow;
      rotate: 90deg;
    }     
  }
  .mySwiperSlide {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;   
    max-width: 350px;
    width: 100%; 
    margin-bottom: 50px;
    @media (max-width: 1200px) {
      max-width: none;
      align-items: center;    
    }   
  }  
  .customBullet {
    width: 9px;
    height: 9px;
    background-color: #FFF4DE;
    border: 1px solid $akcent-yellow;
    transform: rotate(45deg);
    border-radius: 0;
    opacity: 1;    
  }
  .customBulletActive {    
    background-color: $akcent-yellow;       
  }  
}
@import "../../../../styles/index.scss";

.calculator {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #FFF;  
  padding: 50px 30px 30px 30px;  
  width: 100%;
  @media (max-width: 1650px) {
    padding: 40px 20px 20px 20px;
  }  
  @media (max-width: 745px) {
    padding: 30px 20px 18px 20px;
  }
  @media (max-width: 400px) {
    padding: 17px 10px 10px 10px;
  }   
  &__closingBtn {
    position: absolute;    
    cursor: pointer;    
    right: 5%;
    top: 2.5%;    
    @media (max-width: 1650px) {
      svg {
        width: 20px;
        height: 20px;
      }
    }
    @media (max-width: 745px) {
      top: 1.5%;      
    }
  }
  &__wrapperCalculator {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: 920px;
    width: 100%;        
    .title {
      @include mr-500(50px, normal);
      color: $main-text;      
      margin-bottom: 20px;      
      @media (max-width: 1650px) {
        @include mr-500(35px, normal);
        margin-bottom: 15px;
      }
      @media (max-width: 1130px) {
        text-align: center;
      }      
      @media (max-width: 745px) {
        @include mr-500(25px, normal);       
      }
    }
    .subtitle {
      @include montserrat-400(25px, normal);
      color: $main-text;      
      margin-bottom: 40px;
      @media (max-width: 1650px) {
        @include montserrat-400(20px, normal);
        margin-bottom: 25px;
      }
      @media (max-width: 1130px) {
        text-align: center;
      }
      @media (max-width: 1030px) {
        margin-bottom: 20px;
      }
      @media (max-width: 745px) {
        @include montserrat-400(14px, normal);
        margin-bottom: 15px;        
      }
      @media (max-width: 400px) {        
        margin-bottom: 10px;        
      }
           
    }
  }
  .wrapperFormAndImg {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 920px;
    width: 100%;
    @media (max-width: 1130px) {      
      justify-content: center;
      align-items: center;
    }
  }
  .wrapperForm {        
    max-width: 440px;    
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;        
    .form {
      max-width: 440px;
      width: 100%;  
      z-index: 5;               
    }    
    .input {      
      border: 1px solid #7D7D7D;
      @include montserrat-400(16px, normal);
      color: #7D7D7D;
      max-width: 440px;
      width: 100%;
      box-sizing: border-box;
      padding: 18px 0 18px 25px; 
      @media (max-width: 1650px) {
        padding: 15px 0 15px 23px;            
      }      
      @media (max-width: 610px) {
        padding: 10px 0 10px 20px; 
        @include montserrat-400(14px, normal);    
      }       
    }
    .button {      
      max-width: 440px;
      width: 100%; 
      position: relative;
      display: flex;  
      justify-content: center;
      align-items: center;
      padding: 18px 31px;      
      cursor: pointer;
      border: 1.25px solid $akcent-yellow;
      background-color: $akcent-yellow;
      margin-top: 15px;
      @media (max-width: 1650px) {        
        margin-top: 11px;     
      }
      @media (max-width: 610px) {
        padding: 10px 31px;
        margin-top: 10px;     
      }      
      @media (max-width: 470px) {
        max-width: 400px;
      }      
      .wrapperTextBtn {    
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      } 
      .buttonText{
        @include montserrat-500(18px, 98%);
        color: $main-text;
        @media (max-width: 610px) {
          @include montserrat-500(14px, 98%);     
        }
      }     
    }  
    .button::after {
      position: absolute;
      top: 10%;
      content: "";
      display: block;
      max-width: 410px;
      width: 100%;
      min-height: 75px;
      height: 100%;
      border: 1.25px solid $main-text;  
      z-index: -3;
      @media (max-width: 1580px) {
        max-width: 370px;
      }
      @media (max-width: 1515px) {
        max-width: 320px;
      }
      @media (max-width: 1410px) {
        max-width: 270px;
      }
      @media (max-width: 1260px) {
        max-width: 230px;
      }
      @media (max-width: 1130px) {
        max-width: 410px;
      }
      @media (max-width: 850px) {
        max-width: 360px;
      }
      @media (max-width: 745px) {
        max-width: 300px;
      }      
      @media (max-width: 650px) {
        max-width: 260px;  
      }      
      @media (max-width: 610px) {
        min-height: 56px;
      }
      @media (max-width: 550px) {
        max-width: 230px;
      }
      @media (max-width: 480px) {
        max-width: 190px;
      }    
    } 
    .button:hover {
      background-color: $akcent-milk;
    } 
    .wrapperError {
      height: 30px;
      display: flex;
      align-items: center;
      @media (max-width: 1650px) {
        height: 25px; 
      }      
      @media (max-width: 610px) {
        height: 20px; 
      }      
      .error {
        @include montserrat-400(14px, normal);
        color: red;
        @media (max-width: 610px) {
          @include montserrat-400(10px, normal);      
        }         
      }
    }
    .wrapperCheckbox {
      display: flex;
      flex-direction: row;  
      justify-content: flex-start;
      align-items: center;
      margin-top: 20px;      
      @media (max-width: 610px) {
        margin-top: 15px; 
      }      
      &__text {
        @include montserrat-400(16px, normal);
        color: $main-text;
        margin-left: 18px;
        @media (max-width: 610px) {
          @include montserrat-400(12px, normal);
          margin-left: 10px;      
        }         
      }
    }
  }
  .img {
    @media (max-width: 1800px) {
      max-width: 300px;      
      margin-left: 10px;
    }
    @media (max-width: 1410px) {
      max-width: 270px;
    }
    @media (max-width: 1260px) {
      max-width: 240px;
    }
    @media (max-width: 1200px) {
      max-width: 240px;
    }
    @media (max-width: 1130px) {
      display: none;
    }    
  }
}


@import "../../../../styles/index.scss";

.modalForm {  
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;  
  &__closingBtn {
    position: absolute;
    right: 10%;
    top: 5%;
    cursor: pointer;
    @media (max-width: 1000px) {
      right: 5%;     
    }    
    @media (max-width: 500px) {
      top: 2%;
      svg {
        width: 20px;
        height: 20px;
      }
    }    
  }
  &__wrapperForm {      
    padding: 88px 80px 115px 80px;    
    max-width: 600px;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: #FFF;
    @media (max-width: 1000px) {
      padding: 65px 40px;     
    }
    @media (max-width: 770px) {
      padding: 55px 30px;     
    }     
    @media (max-width: 500px) {
      padding: 30px 20px 10px     
    }     
    .title {
      @include mr-500(40px, normal);
      color: $main-text;      
      margin: 0 auto 20px auto;
      text-align: center;
      @media (max-width: 1200px) {
        @include mr-500(35px, normal);
      }
      @media (max-width: 500px) {
        margin-bottom: 10px;     
      }
      @media (max-width: 400px) {
        @include mr-500(25px, normal);
      }
    }
    .subtitle {
      @include montserrat-400(20px, normal);
      color: $main-text;
      text-align: center;
      margin: 0 auto 25px auto;
      @media (max-width: 770px) {
        @include montserrat-400(16px, normal);     
      }
      @media (max-width: 500px) {
        margin-bottom: 15px;     
      }
            
    }
    .form {
      max-width: 450px;
      width: 100%;
      z-index: 5;
    }
    .text {
      @include montserrat-400(16px, normal);
      color: $main-text;
      margin-bottom: 7px;     
      @media (max-width: 500px) {
        @include montserrat-500(12px, normal);      
      }       
    }
    .name, .numberPhone {      
      border: 1px solid #7D7D7D;
      @include montserrat-400(16px, normal);
      color: #7D7D7D;
      max-width: 440px;
      width: 100%;
      box-sizing: border-box;
      padding: 18px 0 18px 25px;      
      @media (max-width: 500px) {
        padding: 10px 0 10px 20px;  
      }     
      @media (max-width: 400px) {
        @include montserrat-400(14px, normal);               
      }
    }
    .button {           
      max-width: 440px;
      width: 100%; 
      position: relative;      
      display: flex;  
      justify-content: center;
      align-items: center;
      padding: 18px 31px;      
      cursor: pointer;
      border: 1.25px solid $akcent-yellow;
      background-color: $akcent-yellow;
      margin-top: 15px;      
      @media (max-width: 500px) {
        padding: 10px 31px;  
      }        
      .wrapperTextBtn {    
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      } 
      .buttonText{
        @include montserrat-500(18px, 98%);
        color: $main-text;
      }          
    } 
    .button::after {      
      position: absolute;
      top: 10%;
      content: "";
      display: block;
      max-width: 410px;
      width: 100%;
      min-height: 75px;
      height: 100%;
      border: 1.25px solid $main-text;  
      z-index: -3;
      @media (max-width: 1118px) {
        max-width: 340px;
      } 
      @media (max-width: 1022px) {
        max-width: 300px;
      } 
      @media (max-width: 780px) {
        max-width: 250px;
      } 
      @media (max-width: 650px) {
        max-width: 200px;
      }      
      @media (max-width: 500px) {
        min-height: 55px;  
      }       
    }
    .button:hover {
      background-color: $akcent-milk;
    }   
    .wrapperError {
      height: 30px;
      display: flex;
      align-items: center;      
      .error {
        @include montserrat-400(14px, normal);
        color: red;        
        @media (max-width: 500px) {
          @include montserrat-400(10px, normal);      
        }              
      }
    }
    .wrapperCheckbox {
      display: flex;
      flex-direction: row;  
      justify-content: flex-start;
      align-items: center;
      margin-top: 20px;
      &__text {
        @include montserrat-400(16px, normal);
        color: $main-text;
        margin-left: 18px;        
        @media (max-width: 500px) {
          @include montserrat-400(12px, normal);
          margin-left: 10px;      
        }      
      }
    }
  }
}

@import "../../../../styles/index.scss";

.ourServices {
  max-width: 3500px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  @media (max-width: 1000px) {
    padding: 0 20px;
    box-sizing: border-box;
  }
  @media (max-width: 450px) {
    padding: 0 10px;      
  } 
  .wrapperOurServices {  
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    max-width: 1150px;
    width: 100%;
    margin-top: 40px;
    @media (max-width: 640px) {
      margin-top: 15px;     
    }
    @media (max-width: 360px) {
      margin-top: 12px;         
    }
    &__title {
      text-transform: uppercase;    
      @include mr-500(80px, 104px, 1.6px);
      color: $main-text;
      margin: 0 auto 35px auto; 
      @media (max-width: 1440px) {
        @include mr-500(60px, normal, 1.2px);         
      }
      @media (max-width: 360px) {
        @include mr-500(35px, normal, 142%);
        margin-bottom: 20px;         
      }              
    }
    &__subtitle {
      @include montserrat-500(25px, normal);
      color: $main-text;
      margin-bottom: 35px;
      max-width: 1000px;
      width: 100%;
      @media (max-width: 360px) {
        @include montserrat-500(18px, normal);         
      }
    }
    &__wrapperPoints {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }    
    .points {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 30px;
      .wrapperText {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        max-width: 820px;
        width: 100%;
        margin-left: 30px;
      }
      .title {
        @include mr-500(35px, normal, 0.7px);
        color: $main-text;
        margin-bottom: 10px;
        @media (max-width: 1440px) {
          @include mr-500(24px, 30px, 0.48px);         
        }
        @media (max-width: 640px) {
          @include mr-500(20px, 30px, 0.48px);         
        }
        @media (max-width: 360px) {
          @include mr-500(16px, 30px, 0.48px);         
        }
      }
    }
    .points:nth-child(2) {
      margin-left: 60px;
      @media (max-width: 1185px) {       
        margin-left: 0;   
      }
    }
    
    .text {
      @include montserrat-500(20px, normal);
      color: $main-text;
      @media (max-width: 1440px) {
        @include montserrat-500(18px, normal);        
      }
      @media (max-width: 640px) {
        @include montserrat-500(16px, normal);        
      }
      @media (max-width: 360px) {
        @include montserrat-500(14px, normal);        
      }
    }
    .textBold {
      @include montserrat-700(20px, normal);
      color: $main-text;
      margin-top: 25px;
      @media (max-width: 1440px) {
        @include montserrat-700(18px, normal);        
      }
      @media (max-width: 640px) {
        @include montserrat-700(16px, normal);        
      }
      @media (max-width: 350px) {
        @include montserrat-700(14px, normal);        
      }
    }    
  }
  .img1 {
    position: absolute;
    max-width: 400px;
    width: 100%;
    left: 1%;
    top: 31%; 
    @media (max-width: 1840px) {
      max-width: 300px;
      top: 36.5%;    
    } 
    @media (max-width: 1670px) {      
      left: -10%;    
    } 
    @media (max-width: 1360px) {      
      max-width: 250px; 
      top: 39.5%;   
    }
    @media (max-width: 1270px) {      
      max-width: 200px; 
      top: 43%;   
    }
    @media (max-width: 1185px) {       
      display: none;   
    }
          
  }
  .img2 {
    position: absolute;
    max-width: 470px;
    width: 100%;
    right: 0;
    bottom: -20%;
    @media (max-width: 1840px) {
      max-width: 370px;    
    }
    @media (max-width: 1670px) {
      max-width: 270px;
      bottom: -10%;    
    }
    @media (max-width: 1440px) {
      max-width: 210px;         
    }
    @media (max-width: 1360px) {       
      bottom: 20%;   
    }
    @media (max-width: 1185px) {       
      display: none;   
    }
    
            
  }
}


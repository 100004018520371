@import "../../../../styles/index.scss";

.ceilingTypes {
  display: flex;
  flex-direction: column;
  justify-content: center;  
  max-width: 1150px;
  width: 100%;
  margin: 100px 0;
  @media (max-width: 1000px) {
    padding: 0 20px;
    box-sizing: border-box;
  }
  @media (max-width: 450px) {
    padding: 0 10px;      
  } 
  &__title {
    @include mr-500(50px, normal, unset);
    color: $main-text;
    margin: 0 auto;
    @media (max-width: 1200px) {
      @include mr-500(35px, normal, unset);   
    }
  }
  &__text {
    @include montserrat-500(20px, 125%);
    color: $main-text; 
    margin-top: 60px;
    margin-bottom: 50px;
    @media (max-width: 1200px) {
      @include montserrat-500(16px, normal);   
    }
    @media (max-width: 640px) {
      @include montserrat-400(18px, normal);   
    }
  }
  &__wrapperBlocks {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    .block {
      display: flex;
      flex-direction: row;
      justify-content: space-between !important;
      align-items: flex-start;
      max-width: 1150px;
      width: 100%;
      margin-bottom: 40px;
      @media (max-width: 1200px) {
        margin-bottom: 30px;   
      }
      @media (max-width: 360px) {
        flex-direction: column;
      }
    }
    .block:last-child {
      margin-bottom: 0;
    }
    .img {
      @media (max-width: 942px) {
        margin-right: 5px;   
      }
      @media (max-width: 360px) {
        margin-right: 0;
      }
    }
    .wrapperText {
      max-width: 750px;
      width: 100%;
    }
    .title {
      @include mr-500(35px, normal, unset);
      color: $akcent-yellow;
      @media (max-width: 1200px) {
        @include mr-500(24px, 30px, 0.48px);   
      }
      @media (max-width: 640px) {
        @include montserrat-700(18px, normal);   
      }
    }
    .text {
      @include montserrat-500(20px, 125%);
      color: $main-text; 
      margin-top: 30px;
      @media (max-width: 1200px) {
        @include montserrat-500(16px, normal);   
      }
      @media (max-width: 640px) {
        @include montserrat-400(18px, normal);   
      }
      @media (max-width: 360px) {
        margin-top: 10px;
      }
    }
  }
}
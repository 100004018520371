@import "../../../../styles/index.scss";

.infoSlider {
  max-width: 1150px;
  width: 100%;
  margin: 100px auto 100px auto;
  @media (max-width: 480px) {
    margin: 50px auto 50px auto;   
  }
  .slide {
    width: 100%;
    height: 100%;
    cursor: pointer;
    @media (max-width: 480px) {
      display: none;   
    }
  }
  .mobileSlide {
    display: none;
    margin: 0 auto;
    cursor: pointer;
    @media (max-width: 480px) {
      display: block;   
    }
  }
  .mySwiperSlide {
    margin-bottom: 50px;    
  }

  .customBullet {
    width: 9px;
    height: 9px;
    background-color: #FFF4DE;
    border: 1px solid $akcent-yellow;
    transform: rotate(45deg);
    border-radius: 0;
    opacity: 1;    
  }
  .customBulletActive {    
    background-color: $akcent-yellow;       
  }
}
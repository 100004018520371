@import "../../../styles/index.scss";

.footer {
  background-color: $main-text;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 3500px;
  width: 100%;
  padding: 100px 0;
  @media (max-width: 540px) {
    padding: 50px 0;       
  }
  .wrapperFooter {    
    display: grid;    
    grid-template-rows: repeat (2 auto);
    grid-template-columns: repeat (4 auto);   
    row-gap: 50px;
    column-gap: 30px;
    max-width: 1150px;
    width: 100%;
    @media (max-width: 640px) {
      grid-template-rows: repeat (3 auto);
      column-gap: 10px;
    }
    @media (max-width: 540px) {
      grid-template-rows: repeat (4 auto);
      grid-template-columns: repeat (2 auto);
      column-gap: 0;      
    }

    .logo {
      grid-row: 1/2;
      grid-column: 1/2; 
      @media (max-width: 540px) {        
        margin-left: 30px;
      }
      @media (max-width: 400px) {        
        margin-left: 10px;
        svg {
          width: 182px;
          height: 185px;
        }
      }
      @media (max-width: 360px) {               
        margin-left: 5px;
        svg {
          width: 162px;
          height: 165px;
        }
      }          
    }
    .ourWorksBlock {
      grid-row: 1/2;
      grid-column: 2/3;
      @media (max-width: 540px) {
        grid-row: 2/3;
        grid-column: 1/2;
        margin-left: 30px;        
      }
      @media (max-width: 400px) {        
        margin-left: 10px;
      }
      @media (max-width: 360px) {        
        margin-left: 5px;
      }         
      
      &__subtitle {
        @include montserrat-700(16px, 20px);
        color: $akcent-milk;
        text-decoration: none;
        margin-bottom: 25px;
        cursor: pointer;
        @media (max-width: 640px) {
          @include montserrat-700(14px, 20px);
        }
        @media (max-width: 540px) {
          @include montserrat-700(12px, 20px);
        }
        @media (max-width: 400px) {
          @include montserrat-700(11px, 20px);
        }
      }      
    }    
    .catalogBlock {
      grid-row: 1/2;
      grid-column: 3/4;
      @media (max-width: 540px) {
        grid-row: 2/3;
        grid-column: 2/3;
        margin-right: 10px;
      }
      &__subtitle {
        @include montserrat-500(16px, normal);
        color: $akcent-milk;
        margin-bottom: 25px;
        text-decoration: none;
        @media (max-width: 640px) {
          @include montserrat-500(14px, normal);
        }
        @media (max-width: 540px) {
          @include montserrat-500(12px, normal);
        }
        @media (max-width: 400px) {
          @include montserrat-500(11px, normal);
        }
      }      
    }
    .servicesBlock {
      grid-row: 1/2;
      grid-column: 4/5;
      @media (max-width: 540px) {
        grid-row: 3/4;
        grid-column: 1/2;
        margin-left: 30px;
      }
      @media (max-width: 400px) {        
        margin-left: 10px;
      } 
      @media (max-width: 360px) {        
        margin-left: 5px;
      }     
      &__subtitle {
        @include montserrat-500(16px, normal);
        color: $akcent-milk;
        margin-bottom: 25px;
        text-decoration: none;
        @media (max-width: 640px) {
          @include montserrat-500(14px, normal);
        }
        @media (max-width: 540px) {
          @include montserrat-500(12px, normal);
        }
        @media (max-width: 400px) {
          @include montserrat-500(11px, normal);
        }
      }      
    }
    .ourWorksBlock, .catalogBlock, .servicesBlock {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;      
      .title {
        @include montserrat-700(20px, normal);
        color: $akcent-milk;
        margin-bottom: 23px;
        text-decoration: none;
        @media (max-width: 640px) {
          @include montserrat-700(15px, normal);
        }
        @media (max-width: 540px) {
          @include montserrat-700(14px, normal);
        }
        @media (max-width: 400px) {
          @include montserrat-700(13px, normal);
        }
      }
      .title:hover {
        text-decoration: underline;
      }
      &__subtitle:hover {
        text-decoration: underline;
      }
      &__subtitle:last-child {
        margin-bottom: 0;
      }
    }    
    .wrapperPrivacyPolicy {
      grid-row: 2/3;
      grid-column: 1/2;      
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      opacity: 0.6;
      @media (max-width: 640px) {
        grid-row: 3/4;
        grid-column: 1/5;        
      }
      @media (max-width: 540px) {
        grid-row: 4/5;
        grid-column: 1/3;
        justify-content: center;
        align-items: center;
        text-align: center;
      }      
      .privacyPolicy {
        @include montserrat-500(16px, normal);
        color: $akcent-milk;
        &__link {
          color: $akcent-yellow;
          text-decoration: none;
        }
        @media (max-width: 640px) {
          @include montserrat-500(14px, normal);
        }
      }     
    }
    .wrapperTime {
      grid-row: 2/3;
      grid-column: 2/3;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      @media (max-width: 540px) {
        grid-row: 1/2;
        grid-column: 2/3;
        justify-content: center; 
        margin-right: 10px;       
      }
      
      .bold {
        @include montserrat-700(16px, normal);        
      }
      &__text {
        @include montserrat-500(16px, normal);
        color: $akcent-milk;
        @media (max-width: 640px) {
          @include montserrat-500(14px, normal);
        }
        @media (max-width: 400px) {
          @include montserrat-500(13px, normal);
        }
      }
      &__text:last-child {
        margin-top: 10px;
      }
    }
    .wrapperNumber {
      grid-row: 2/3;
      grid-column: 3/4;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      @media (max-width: 540px) {
        grid-row: 3/4;
        grid-column: 2/3;
        margin-right: 10px;
      }
      &__text, &__textUnderline {
        @include montserrat-700(16px, 20px);
        color: $akcent-milk;
        margin-bottom: 10px;
        @media (max-width: 640px) {
          @include montserrat-700(14px, 20px);
        }
        @media (max-width: 400px) {
          @include montserrat-700(13px, normal);
        }
      }
      &__textUnderline {
        padding-bottom: 10px;
        border-bottom: 1.6px solid  $akcent-yellow;
        margin-bottom: 10px;
        cursor: pointer;       
      }
      &__textUnderline:last-child {
        margin-bottom: 0;
      }
    }
  } 
}


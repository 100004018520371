@import "../../../../styles/index.scss";

.privacyPolicy {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-width: 1150px;
  width: 100%;
  margin: 100px 0;
  @media (max-width: 1000px) {
    padding: 0 20px;
    box-sizing: border-box;
  }   
  @media (max-width: 640px) {
    margin: 70px 0;   
  }
  @media (max-width: 560px) {
    margin: 50px 0;   
  }
  @media (max-width: 450px) {
    padding: 0 10px;      
  }
  &__title {
    @include mr-500(80px, normal, 1.6px);
    color: $main-text;
    text-align: center;
    margin-bottom: 60px;
    @media (max-width: 1200px) {
      @include mr-500(60px, normal, 1.2px);
      margin-bottom: 50px;   
    }
    @media (max-width: 640px) {
      @include mr-500(55px, normal, 1.1px);
      margin-bottom: 30px;   
    }
    @media (max-width: 560px) {
      @include mr-500(25px, normal);        
    }
  }
  &__wrapperText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 20px;
  }
  &__wrapperText:last-child {
    margin-bottom: 0;
  }
  &__text {
    @include montserrat-500(20px, 140%);
    color: $main-text;
    @media (max-width: 1200px) {
      @include montserrat-500(18px, normal);   
    }
    @media (max-width: 1200px) {
      @include montserrat-400(16px, normal);   
    }
  }
  &__li {
    margin-left: 8px;
  }
}
@import "../../../../styles/index.scss";

.tablePrice {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;  
  width: 100%;
  margin-bottom: 60px;  
  &__wrapperTitle {
    max-width: 1150px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
   
    .title {
      text-transform: uppercase;
      @include mr-500(50px, normal);
      color: $main-text;
      margin-left: 15px;
      @media (max-width: 1200px) {
        @include mr-500(35px, normal);    
      }
      @media (max-width: 560px) {
        @include mr-500(25px, normal);    
      }      
    }
    
  }
  &__wrapperTable {
    max-width: 1200px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (max-width: 1200px) {
      max-width: 1164px;   
    }
     .table {      
      border-collapse: separate;
      border-spacing: 25px 15px;
      @media (max-width: 1200px) {
        border-spacing: 12px 7px;   
      }
      @media (max-width: 560px) {
        border-spacing: 5px 3px;   
      }               
    }    
    .headText {
      @include mr-500(14px, normal);
      color: $main-text;
      border: none;
      padding: 0;
      @media (max-width: 770px) {
        @include mr-500(12px, normal);   
      }
      @media (max-width: 560px) {
        @include mr-500(10px, normal);    
      }
    }
    .bodyCell {
      @include montserrat-500(18px, normal);
      color: $main-text;
      border: 1px solid $main-text;
      padding: 17px 40px;
      @media (max-width: 1200px) {
        padding: 10px 30px;   
      }
      @media (max-width: 770px) {
        @include montserrat-500(16px, normal);
        padding: 6px 15px;   
      } 
      @media (max-width: 560px) {
        @include montserrat-500(14px, normal);
        padding: 2px 4px;   
      }             
    }
    .button {
      width: 100%;
      margin-top: 20px;
      display: flex;      
      justify-content: center;
      @media (max-width: 560px) {
        margin-top: 20px;   
      }      
    }
  }
}
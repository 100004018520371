@import "../../../../styles/index.scss";

.photoCeilings {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1150;  
  width: 100%;
  margin-bottom: 70px;
  &__title {
    @include mr-500(45px, normal);
    color: $main-text;
    text-transform: uppercase;
    margin-bottom: 40px;
    @media (max-width: 1200px) {
      @include mr-500(35px, normal);    
    }
    @media (max-width: 705px) {
      @include mr-500(25px, 30px, 0.5px);
      margin-bottom: 30px;    
    }
    @media (max-width: 560px) {
      @include mr-500(20px, normal); 
      margin-bottom: 15px;   
    }
    
  }
  &__wrapperBlocks {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    @media (max-width: 705px) {
      display: none;
    }
    .wrapperTextAndImg {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      max-width: 350px;
      width: 100%;
      margin-bottom: 45px;
      @media (max-width: 1200px) {
        margin-bottom: 35px;    
      }
    }
    .text {
      @include montserrat-400(18px, normal);
      color: $main-text;
      margin-top: 10px;
      @media (max-width: 1200px) {
        @include montserrat-400(16px, normal);    
      }
    }
  }
  &__wrapperBlocksMobile {
    display: none;   
    max-width: 705px;
    width: 100%;
    @media (max-width: 705px) {
      display: block;      
    } 
    .mySwiperSlideMobile {
      @media (max-width: 560px) {
        display: flex;
        justify-content: center;      
      }     
    } 
    
    .wrapperTextAndImgMobile {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start; 
      max-width: 350px;
      width: 100%;          
      .text {        
        @include montserrat-400(16px, normal);
        color: $main-text;
        margin-top: 10px;
        @media (max-width: 560px) {
          @include montserrat-400(15px, normal);    
        }        
      }
    }

    .customBullet {
      width: 9px;
      height: 9px;
      background-color: #FFF4DE;
      border: 1px solid $akcent-yellow;
      transform: rotate(45deg);
      border-radius: 0;
      opacity: 1;    
    }
    .customBulletActive {    
      background-color: $akcent-yellow;       
    }
    .mySwiperSlideMobile {
      margin-bottom: 60px;      
    }    
  }
  
  &__button {
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 312px;
    z-index: 4;
    position: relative;
    margin-top: 15px;
    text-decoration: none;
  }
}

@import "../../../../styles/index.scss";

.repair {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;  
  max-width: 3500px;
  width: 100%;
  margin: 100px 0;
  position: relative;
  @media (max-width: 1000px) {
    padding: 0 20px;
    box-sizing: border-box;
  }
  @media (max-width: 450px) {
    padding: 0 10px;      
  } 
  @media (max-width: 360px) {
    margin: 50px 0;        
  }
  
  .wrapperRepair {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;  
    max-width: 1150px;
    width: 100%;
    &__title {
      @include mr-500(50px, normal, unset);
      color: $main-text;
      margin-bottom: 40px;
      @media (max-width: 1200px) {
        @include mr-500(35px, normal, unset);        
      }
      @media (max-width: 360px) {
        @include mr-500(25px, normal, unset);
        margin-bottom: 20px;        
      }
    }
    &__subtitle {
      @include montserrat-500(25px, 154%);
      color: $main-text;
      margin: 0 auto 40px 0;
      @media (max-width: 1200px) {
        @include montserrat-500(20px, normal);       
      }
      @media (max-width: 360px) {
        @include montserrat-500(16px, 110%);
        margin-bottom: 15px;        
      }
    }
    &__button {
      width: 100%;
      max-width: 312px;
      z-index: 4;
      position: relative;
      display: flex;    
      justify-content: center;          
    }
    &__repairImg {
      width: 100%;      
      margin-top: 80px;
      @media (max-width: 360px) {
        display: none;                
      }
    }
    &__repairImgMobile {
      display: none;
      @media (max-width: 360px) {
        display: block;        
        margin-top: 60px;
        max-width: 180px;
        width: 100%;        
      }
    }
    &__points {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      max-height: 1000px;
      height: 100%;
      width: 100%;
      @media (max-width: 1040px) {       
        align-items: flex-start;
        flex-wrap: nowrap;
        max-height: none;  
      }
      .point {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        max-width: 530px;
        width: 100%;
        margin-bottom: 30px;
        @media (max-width: 1040px) {       
          max-width: 1040px;
          margin-bottom: 20px;  
        }
      }
      .point:last-child {
        margin-bottom: 0;
      }
      .wrapperTitle {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        @media (max-width: 360px) {
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          max-width: 360px;
          width: 100%;        
        }
      }
      .icon {
        @media (max-width: 360px) {
          margin: 0 auto;       
        }
      }
      .title {
        @include montserrat-700(20px, 20px);
        color: $akcent-yellow;
        margin-left: 10px;
        @media (max-width: 1200px) {
          @include montserrat-700(18px, 130%);       
        }
        @media (max-width: 360px) {
          @include montserrat-700(16px, normal);
          margin-left: 0;
          margin-bottom: 10px;       
        }        
      }
      .text {
        @include montserrat-500(20px, 140%);
        color: $main-text;
        @media (max-width: 1200px) {
          @include montserrat-500(18px, normal);       
        }
        @media (max-width: 360px) {
          @include montserrat-500(16px, normal);      
        }
      }
    }        
  }
  .outlineLeft {
    position: absolute;
    left: 0;
    top: 0;
    max-width: 300px;
    width: 100%;
    @media (max-width: 1690px) {
      max-width: 250px;    
    }
    @media (max-width: 1500px) {
      max-width: 200px;
      top: 8%;    
    }
    @media (max-width: 1295px) {
      max-width: 150px;
      top: 10%;    
    }
    @media (max-width: 1295px) {      
      top: 8%;
      max-width: 110px;    
    }
    @media (max-width: 1160px) {      
      display: none;    
    }
  }
  .outlineRight {
    position: absolute;
    right: 0;
    max-width: 400px;
    width: 100%;
    @media (max-width: 1690px) {
      max-width: 300px;    
    }
    @media (max-width: 1500px) {
      max-width: 220px;    
    }
    @media (max-width: 1295px) {
      bottom: 43%;    
    }
    @media (max-width: 1295px) {      
      max-width: 170px;    
    }
    @media (max-width: 1160px) {      
      display: none;    
    }
  } 
}


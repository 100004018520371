@import "../../../../styles/index.scss";

.design {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;  
  max-width: 1150px;
  width: 100%;
  margin: 100px 0;
  @media (max-width: 1000px) {
    padding: 0 20px;
    box-sizing: border-box;
  }
  @media (max-width: 450px) {
    padding: 0 10px;      
  } 
  &__title {
    @include mr-500(50px, normal, unset);
    color: $main-text;
    margin-bottom: 40px;
    @media (max-width: 1200px) {
      @include mr-500(35px, normal, unset);    
    }
    @media (max-width: 470px) {
      @include mr-500(25px, normal, unset);    
    }    
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .text {
      @include montserrat-500(20px, 142.9%);
      color: $main-text;
      @media (max-width: 1200px) {
        @include montserrat-500(18px, normal);   
      }
      @media (max-width: 640px) {
        @include montserrat-500(16px, normal);   
      }
      @media (max-width: 470px) {
        @include montserrat-500(14px, normal);   
      }
    }
    .wrapperPoints{
      display: flex;
      flex-direction: column;
      justify-content: center;      
      margin: 30px 0;
      width: 100%;
      @media (max-width: 470px) {
        margin: 20px 0 5px 0;   
      }
    }
    .designImgTablet {
      display: none;
      @media (max-width: 1040px) {
        display: block;
        max-width: 560px;
        width: 100%;
        margin: 0 auto;
        margin-bottom: 20px;
      }
    }
    .point {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center; 
      @media (max-width: 1040px) {
        margin-bottom: 20px;
      }
      @media (max-width: 470px) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;   
      }     
    }
    .title {
      @include mr-500(35px, normal);
      color: $akcent-yellow;
      @media (max-width: 1200px) {
        @include mr-500(24px, 30px, 0.48px);   
      }
      @media (max-width: 470px) {
        @include mr-500(24px, 154%);
        margin-bottom: 15px;   
      }
    }
    .wrapperTextAndImg {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      max-width: 880px;
      width: 100%;
      @media (max-width: 1040px) {
        max-width: 600px;
      } 
      @media (max-width: 755px) {
        max-width: 450px;
      } 
      @media (max-width: 615px) {
        max-width: 300px;
      } 
      @media (max-width: 470px) {
        max-width: 470px;
      }   
    }
    .img {
      max-width: 230px;
      width: 100%;
      margin-right: 20px;
      @media (max-width: 1040px) {
        display: none;         
      }
    }
  }  
  
  &__button {
    width: 100%;
    max-width: 312px;
    z-index: 4;
    position: relative;
    margin-top: 40px;
    display: flex;    
    justify-content: center;    
    .mainButtonDark::after {
      border: 1.25px solid $main-text;
    }
  }
  
}


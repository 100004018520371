@import "../../../../styles/index.scss";

.lamps {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;  
  max-width: 1150px;
  width: 100%;
  margin-bottom: 50px;
  @media (max-width: 1000px) {
    padding: 0 20px;
    box-sizing: border-box;
  }
  @media (max-width: 450px) {
    padding: 0 10px;      
  } 
  &__title {
    text-transform: uppercase;
    @include mr-500(80px, normal, 1.6px);
    color: $main-text;
    margin-bottom: 40px;
    @media (max-width: 1440px) {
      @include mr-500(60px, normal, 1.2px);
    }
    @media (max-width: 840px) {
      @include mr-500(35px, normal);
    }
  }
  .wrapperTextImg {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 1150px;
    width: 100%;
    @media (max-width: 630px) {
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
    }
  }
  &__text {
    @include montserrat-500(18px, normal);
    color: $main-text;
    max-width: 550px;
    width: 100%;
    @media (max-width: 1200px) {
      @include montserrat-400(16px, normal);
    }
    @media (max-width: 630px) {
      max-width: 400px;
    }
        
  }
  &__img {
    max-width: 550px;
    width: 100%;
    @media (max-width: 840px) {
      max-width: 400px;
    }
    @media (max-width: 630px) {
      margin-bottom: 20px;
    }
  }
}
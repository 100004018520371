.App {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;   
  min-height: 100vh;  
  @media (max-width: 360px) {
    justify-content: center;
  }
}


@import "../../../../styles/index.scss";

.ourPrices {
  background-color: #FCF9F3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 3500px;
  width: 100%;
  padding: 100px 0;  
  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;    
    max-width: 1150px;
    width: 100%;
  }
  &__title {
    @include mr-500(35px, normal, unset);
    color: $main-text;
    @media (max-width: 1030px) {
      text-align: center;            
    }
    @media (max-width: 415px) {
      @include mr-500(35px, normal, unset);            
    }
  }
  &__subtitle {
    @include montserrat-700(25px, normal);
    color: $main-text;
    margin-top: 40px;
    @media (max-width: 1030px) {
      text-align: center;            
    }
    @media (max-width: 415px) {
      @include montserrat-700(18px, normal);            
    }
    
  }
  &__button {
    width: 100%;
    max-width: 312px;
    z-index: 4; 
    display: flex;
    justify-content: center; 
    text-decoration: none;  
  }  
  &__blocks {
    display: flex;
    flex-direction: row;    
    justify-content: space-between;
    align-items: center;
    margin-top: 54px;
    margin-bottom: 33px;
    max-width: 1150px;
    width: 100%;
    @media (max-width: 1030px) {
      flex-direction: column;            
    }
    .block {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border: 1px solid  $main-text;
      background-color: #FFF;
      padding: 50px 42px 34px 42px;
      @media (max-width: 1030px) {
        margin-bottom: 40px;
      }      
    }
    @media (max-width: 1030px) {       
      .block:last-child {
        margin-bottom: 0;
      }     
    }


    .icon {
      position: absolute;
      top: -12.7%;
    }
    .title {
      @include montserrat-500(22px, normal);
      color: $main-text;
      margin-bottom: 10px;
    }
    .price {
      @include montserrat-500(22px, normal);
      color: $main-text;
      margin-bottom: 30px;
    }
    .bold {
      @include montserrat-700(30px, normal);
      color: $main-text;
    }
    .specifications {
      @include montserrat-500(16px, normal);
      color: $main-text;
      margin: 0 auto 15px 0;      
    }
    .width, .production, .text, .guarantee {
      @include montserrat-500(14px, normal);
      color: $main-text;      
      margin: 0 auto 12px 0;      
    }
  }
}
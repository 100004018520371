@import "../../../styles/index.scss";

.wrapperHeader {
  display: flex;
  justify-content: center;
  background-color: $main-text;
  width: 100%;
  padding-bottom: 10px;
}
.wrapperInstallation, .wrapperDesign, .wrapperRepair {
  width: 100%;
  display: flex;
  justify-content: center;
}
@import "../../../../styles/index.scss";

.ceilingDescription {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1150;  
  width: 100%;
  margin: 70px 0 60px 0;
  &__title {
    @include mr-500(45px, normal);
    text-transform: uppercase;
    margin-bottom: 36px;
    @media (max-width: 1200px) {
      @include mr-500(35px, normal);
    }
    @media (max-width: 745px) {
      @include mr-500(25px, normal);
      text-align: center;
    }
    @media (max-width: 360px) {
      @include mr-500(20px, normal);
      margin-bottom: 15px;      
    }
    .yellow {
      color: $akcent-yellow;
    }
    .black {
      color: $main-text;
    }
  }
  &__wrapperTextAndImg {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    @media (max-width: 745px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .wrapperText {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      max-width: 550px;
      width: 100%;
      @media (max-width: 745px) {
        max-width: 745px;
        margin-bottom: 37px;
      }
      @media (max-width: 360px) {       
        margin-bottom: 27px;
      }
    }
    .text {
      @include montserrat-500(20px, 140%);
      color: $main-text;
      margin-bottom: 26px;
      @media (max-width: 1200px) {
        @include montserrat-500(16px, normal);
      }
    }
    .text:last-child {
      margin-bottom: 0;
    }
    .img {
      max-width: 550px;
      width: 100%;
      @media (max-width: 745px) {
        max-width: 745px;
      }
    }
  }
  
}

@import "../../../../styles/index.scss";

.installation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;  
  max-width: 1150px;
  width: 100%;
  margin: 100px 0;
  @media (max-width: 1000px) {
    padding: 0 20px;
    box-sizing: border-box;
  }  
  @media (max-width: 480px) {
    margin: 50px 0;        
  }
  @media (max-width: 450px) {
    padding: 0 10px;      
  } 
  &__title {
    @include mr-500(50px, normal, unset);
    color: $main-text;
    margin-bottom: 40px;
    @media (max-width: 1200px) {
      @include mr-500(35px, normal, unset);    
    }
    @media (max-width: 480px) {
      margin-bottom: 30px;
      @include mr-500(25px, normal, unset);    
    }
  }
  &__wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    @media (max-width: 480px) {
      flex-direction: column;        
    }
  }
  .text {
    @include montserrat-500(20px, 140%);
    color: $main-text;
    @media (max-width: 1200px) {
      @include montserrat-400(18px, normal);    
    }
    @media (max-width: 640px) {
      @include montserrat-400(16px, normal);    
    }
    @media (max-width: 480px) {
      @include montserrat-400(14px, normal);    
    }
  }
  &__wrapperTextAndImg {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    max-width: 650px;
    width: 100%;
    @media (max-width: 950px) {
      margin-right: 40px;    
    }
    .img {
      max-width: 550px;
      width: 100%;
      margin-top: 30px;
      @media (max-width: 480px) {
        margin-top: 25px;        
      }      
    }
  }
  &__wrapperPointsAndText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    max-width: 400px;
    width: 100%;
    @media (max-width: 480px) {
      margin-top: 25px;
      max-width: 480px;        
    }
    .titleText {
      @include montserrat-500(25px, 154%);
      color: $main-text;
      margin-bottom: 15px;
      @media (max-width: 1200px) {
        @include montserrat-400(18px, normal);    
      }
      @media (max-width: 640px) {
        @include montserrat-400(16px, normal);    
      }
      @media (max-width: 480px) {
        @include montserrat-400(14px, normal);    
      }
    }
    .wrapperPoints {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      margin: 15px 0 35px 0;
      @media (max-width: 480px) {
        margin-bottom: 15px;        
      }      
    }
    .point {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 15px;      
    }
    .point:last-child {
      margin-bottom: 0;
    }
    .icon {
      margin-right: 12px;
    }
    .button {
      width: 100%;
      max-width: 312px;
      z-index: 4;
      position: relative;
      margin-top: 35px;
      @media (max-width: 480px) {
        margin: 0 auto;        
        margin-top: 25px;
        display: flex;
        justify-content: center;               
      }
    }
  }
}


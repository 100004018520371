@import "../../../../styles/index.scss";

.workWithUs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  background-color: #F8F3E9;
  max-width: 1150px;
  width: 100%;
  padding: 50px 0 0 100px;
  margin: 100px 0;
  box-sizing: border-box; 
  position: relative;   
  @media (max-width: 1090px) {
    position: relative;
    overflow: hidden;
  }
  @media (max-width: 1000px) {    
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
  }
   
  @media (max-width: 570px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    padding-top: 32px;
    padding-left: 20px;
    padding-right: 20px;
    margin: 50px 0;    
  }
  @media (max-width: 450px) {
    padding-left: 10px;
    padding-right: 10px;      
  }

  .wrapperInfo {
    margin-bottom: 50px;
    @media (max-width: 570px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;    
    }
  }
  &__title {
    @include mr-500(50px, normal, unset);
    color: $main-text;
    @media (max-width: 570px) {
      @include mr-500(25px, normal, unset);  
    }
  }
  &__subtitle {
    @include montserrat-500(25px, 154%);
    color: $main-text;
    margin-top: 7px;
    margin-bottom: 35px;
    @media (max-width: 570px) {
      @include montserrat-500(18px, 110%);  
    }
  }
  &__wrapperStages {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 55px;
    max-width: 1150px;
    width: 100%;    

    .wrapperStage {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 25px;
    }
    .wrapperStage:last-child {
      margin-bottom: 0;
    }
    .wrapperText {
      margin-left: 14px;
    }
    .title {
      @include montserrat-700(20px, 20px);
      color: $main-text;
      @media (max-width: 820px) {
        @include montserrat-700(18px, normal);
      }
      @media (max-width: 570px) {
        @include montserrat-700(16px, normal);
      }      
    }
    .text {
      @include montserrat-400(16px, normal);
      color: $main-text;
      margin-top: 7px;
      @media (max-width: 960px) {
        max-width: 350px;
        width: 100%;        
      }
      @media (max-width: 820px) {
        max-width: 300px;
        width: 100%;
        @include montserrat-400(14px, normal);
      }
    }
  }
  &__img {
    max-width: 574px;
    width: 100%;    
    position: absolute;
    right: 0;
    bottom: 0;
    @media (max-width: 890px) {
      max-width: 350px;      
    }
    @media (max-width: 660px) {
      max-width: 320px;      
    }
    @media (max-width: 620px) {
      display: none;      
    }    
  }
  &__imgMobile {
    display: none;
    @media (max-width: 620px) {
      display: block;
      // max-width: 312px;
      max-width: 230px;
      width: 100%;
    }
  }
  &__button {
    width: 100%;
    max-width: 312px;
    z-index: 4;
    position: relative;
    display: flex;
    justify-content: center;
  }
}
@import "../../../styles/index.scss";

.customBreadcrumbs {
  max-width: 1150px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin: 45px 0 30px 0;
  @media (max-width: 1000px) {
    padding: 0 20px;
    box-sizing: border-box;
  }
  @media (max-width: 450px) {
    padding: 0 10px;      
  } 
  
  .breadcrumbs {
    .customSeparator {
      @include montserrat-500(18px, normal);
      color: $main-text;
      @media (max-width: 1440px) {
        @include montserrat-500(16px, normal);
      }
    }
    .linkMain {
      @include montserrat-400(18px, normal);
      color: $main-text;
      @media (max-width: 1440px) {
        @include montserrat-400(16px, normal);
      }
    }
    
    .currentPage {
      @include montserrat-500(18px, normal);
      color: $main-text;
      @media (max-width: 1440px) {
        @include montserrat-500(16px, normal);
      }
    }
    
  }
  
}
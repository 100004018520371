@import "../../../styles/index.scss";

.reviewsCard {
  padding: 30px 50px;
  max-width: 250px;
  width: 100%;  
  min-height: 450px;  
  border: 2px solid $akcent-yellow;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;  
  position: relative;
  @media (max-width: 1200px) {
    padding: 25px 40px;    
  }
  @media (max-width: 680px) {
    padding: 15px 20px;    
  }
  
  &__name {
    @include montserrat-700(20px, 20px);
    color: $main-text;
    margin-bottom: 20px;
    @media (max-width: 1200px) {
      @include montserrat-700(18px, 130%);    
    }
    @media (max-width: 680px) {
      @include montserrat-700(16px, normal);    
    }
  }
  &__text {
    @include montserrat-400(16px, normal);
    color: $main-text;
    margin-top: 23px;
    @media (max-width: 1200px) {
      @include montserrat-400(14px, normal);    
    }
  }
  &__iconRhombus{
    position: absolute;
    top: -9%;    
  }
}
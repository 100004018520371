@import "../../../../styles/index.scss";

.tables {
  max-width: 1200px;
  width: 100%;
  margin-bottom: 50px;
  @media (max-width: 1200px) {
    max-width: 1164px;   
  }
  @media (max-width: 560px) {
    margin-bottom: 20px;   
  }
}
@import "../../../styles/index.scss";

.wrapper {
  position: relative;
  max-width: 3500px;
  width: 100%;
  min-height: 1180px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-image: url(../../../assets/img/descriptionBackground.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;    
}
.wrapperStocks {
  width: 100%;  
}
.wrapperOurWorks, .wrapperOrderStages, .wrapperReviews {
  width: 100%;
  display: flex;
  justify-content: center;
}
@import "../../../styles/index.scss";

.cardOurWorks {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;      
  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    max-width: 350px;
    width: 100%;
    border: 2px solid #CC9300;
    border-top: none;
    padding-bottom: 30px;
    padding-top: 20px;
    height: 100%;
  }
  &__img {
    max-width: 354px;
    width: 100%;
    max-height: 192.16px;
    height: 100%;
  }
  &__title {
    @include montserrat-700(20px, 20px);
    color: $main-text;    
    padding: 0 25px;
    @media (max-width: 1200px) {
      @include montserrat-700(18px, 130%);    
    }
    @media (max-width: 1070px) {
      padding: 0 15px;    
    }
    @media (max-width: 470px) {
      @include montserrat-700(16px, normal);    
    }       
  }  
  &__address {
    @include montserrat-500(20px, 125%);
    color: $main-text;
    margin-top: 20px;    
    padding-left: 25px;
    @media (max-width: 1200px) {
      @include montserrat-500(18px, normal);    
    }
    @media (max-width: 1070px) {
      padding-left: 15px;    
    }
    @media (max-width: 470px) {
      @include montserrat-500(16px, normal);    
    }
  }
  &__wrapperInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-top: 25px;
    padding: 0 25px;
    @media (max-width: 1070px) {
      padding: 0 15px;    
    }
    .info {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 15px;
    }
    .text {
      @include montserrat-500(20px, 125%);
      color: $main-text;
      margin-left: 10px;
      @media (max-width: 1200px) {
        @include montserrat-400(16px, normal);    
      }      
    }
  }
  &__price {
    position: relative;
    display: flex;    
    justify-content: center;
    align-items: center;
    @include montserrat-700(25px, normal);
    color: $main-text;
    padding: 4px 25px;
    background:  #FFF4DE;
    margin: 15px auto 0 auto;
    @media (max-width: 1200px) {
      @include montserrat-700(18px, 130%);    
    }
    @media (max-width: 470px) {
      @include montserrat-700(16px, normal);    
    }     
  }
  &__price::after {
    position: absolute;
    top: 15%;
    right: 10%;
    content: "";
    display: block;
    max-width: 240px;
    width: 100%;
    min-height: 45px;
    height: 100%;
    border: 1.25px solid #FFF4DE;   
    z-index: -3;
  }

}
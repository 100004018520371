@import "../../../../styles/index.scss";

.latestProjects {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1150;  
  width: 100%;
  margin-bottom: 80px;
  @media (max-width: 1200px) {
    margin-bottom: 60px;    
  }
  @media (max-width: 790px) {
    margin-bottom: 50px;    
  }
  @media (max-width: 470px) {
    margin-bottom: 30px;    
  }
  &__title {
    text-transform: uppercase;
    @include mr-500(45px, normal, unset);
    color: $main-text;
    margin-bottom: 55px;
    @media (max-width: 1200px) {
      @include mr-500(35px, normal, unset);
      margin-bottom: 40px;    
    }
    @media (max-width: 790px) {
      text-align: center; 
      margin-bottom: 30px;   
    }
    @media (max-width: 470px) {
      @include mr-500(25px, normal, unset);
      margin-bottom: 15px;          
    }
  }
  &__wrapperCards {
    display: flex;
    flex-direction: row;
    justify-content: space-between;    
    align-items: stretch;    
    max-width: 1150px;
    width: 100%;    
    @media (max-width: 1070px) {
      display: none;
    }  
  }
  &__wrapperBlocksMobile {
    display: none;   
    max-width: 620px;
    width: 100%;
    @media (max-width: 1070px) {
      display: block;
    }
    .customBullet {
      width: 9px;
      height: 9px;
      background-color: #FFF4DE;
      border: 1px solid $akcent-yellow;
      transform: rotate(45deg);
      border-radius: 0;
      opacity: 1;    
    }
    .customBulletActive {    
      background-color: $akcent-yellow;       
    }
    .mySwiperSlideMobile {
      margin-bottom: 60px;
    }    
  }
}
